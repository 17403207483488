import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../styles/blog.scss'

export default function Blog({ children, location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="Blog" />
			<div className="blog__title">
				<div className="blog__texture" />
				<h1>BLOG</h1>
			</div>
		</Layout>
	)
}
